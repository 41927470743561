import { RefreshOutlined } from '@mui/icons-material'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { isEmpty, cloneDeep, uniqBy, orderBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFirstRender } from 'utils/hooks/useFirstRender'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import { loadRepeatableColumns } from '../../../../data/dataServices'
import SideDialog from '../../components/SideDialog'
import { generateRandomID } from '../../helpers'
import useFields from '../../hooks/useFields'
import useView from '../../hooks/useView'
import DashboardList from '../DashboardKeyList'
import useTileQuery from '../../hooks/useTileQuery'
import useGroupQuery from '../../hooks/useGroupQuery'
import { NOT_SUPPORT_ACTION_VIEW_TYPES } from 'components/data/datagrid/CreatViewComponents/ViewDialogUtils'
import DatagridTileLabel from './DatagridTileLabel'

function DatagridSettingsDialog({
    defaultTileWidth,
    tileElementWidth,
    dashboardKeyList,
    settings,
    isSubmitting,
    open,
    tile,
    onClose,
    onSubmit,
    onResizeTileWidth,
    tileEditSettingsComponent,
    dashboardKey,
    menuKeyInUrl,
}) {
    const { environment } = useSelector((state) => state)
    const [t] = useTranslation('common')
    const isFirstRender = useFirstRender()

    const { groups } = useGroupQuery()

    const selectedWebGroupKey = useMemo(() => {
        if (!Array.isArray(groups) || !groups?.length) return ''

        return groups?.find((group) => group?.menuKey != null && group?.menuKey === menuKeyInUrl)
            ?.key
    }, [groups, menuKeyInUrl])
    const OTHER_OPTIONS = [
        {
            label: t('common:chart.showDataNames'),
            value: 'showDataNames',
        },
        {
            label: t('common:chart.showRepeatables'),
            value: 'showRepeatables',
        },
        {
            label: t('common:chart.enableDataExport'),
            value: 'enableExport',
        },
        {
            label: t('common:chart.enableActiveUpdate'),
            value: 'enableActiveUpdate',
        },
        {
            label: t('common:chart.allowViewEdit'),
            value: 'allowViewEdit',
        },
        {
            label: t('common:chart.allowDispatch'),
            value: 'allowDispatch',
        },
        //		{
        //			label: t('common:chart.showForm'),
        //			value: 'showForm',
        //		},
        {
            label: t('common:chart.hideHeaders'),
            value: 'hideHeaders',
        },
        {
            label: t('common:chart.hideTotalRows'),
            value: 'hideTotalRows',
        },
        // {
        // 	label: t('common:chart.addNewButton'),
        // 	value: 'addNewButton',
        // },
        {
            label: t('common:chart.showViewFilter'),
            value: 'showViewFilter',
        },
    ]

    const { viewLoading, views, reloadViews } = useView({ config: { open }, selectedWebGroupKey })

    const [alertInfo, setAlertInfo] = useState({
        open: false,
        text: '',
        type: 'success',
    })
    const [primaryKeyList, setPrimaryKeyList] = useState([])

    const [settingsData, setSettingsData] = useState({
        viewKey: '',
        style: 'compact',
        columnsChecked: [],
        // Enable active update and export by default
        otherOptionsChecked: ['enableActiveUpdate', 'enableExport'],
        linkedFields: {},
    })

    const isShowDataNames = settingsData?.otherOptionsChecked?.includes('showDataNames')
    const isIncludeRepeatFields = settingsData?.otherOptionsChecked?.includes('showRepeatables')
    const isShowViewFilter = settingsData?.otherOptionsChecked?.includes('showViewFilter')
    const isFormList = tile && 'FormList' == tile?.type

    const selectedView = useMemo(() => {
        if (!views) return []

        return views.find((view) => view.key === settingsData?.viewKey)
    }, [settingsData.viewKey, views])

    const tmpOpt = useMemo(
        () =>
            OTHER_OPTIONS.map((item) => {
                if (isFormList && 'enableActiveUpdate' !== item.value) {
                    return { ...item, hide: true }
                }
                if (item.value === 'allowViewEdit') {
                    return { ...item, hide: NOT_SUPPORT_ACTION_VIEW_TYPES.includes(selectedView?.type)}
                }
                return item
            }),
        [isFormList, selectedView]
    )

    const { fieldLoading, fields } = useFields({
        viewKey: settingsData?.viewKey,
        viewType: settingsData?.viewType,
    })

    const { tileList, isLoading } = useTileQuery({
        dashboardKey,
        enableConfig: true,
    })
    const doformsTile = useMemo(() => {
        const currentList =
            tileList?.length > 0 ? tileList?.filter((tile) => tile?.type === 'DoFormsForm') : []
        return currentList
    }, [tileList])

    useEffect(() => {
        setSettingsData((prev) => ({ ...prev, ...settings }))
    }, [settings])

    useEffect(() => {
        if (dashboardKeyList) {
            const newPrimaryKeyList = dashboardKeyList
                .filter((item) => item.tileName === tile.i)
                .map((item) => item.key)
            setPrimaryKeyList(newPrimaryKeyList)
        }
    }, [dashboardKeyList])

    useEffect(() => {
        if (isFirstRender) return

        if (settingsData?.viewKey) {
            handleSettingsChange('columnsChecked', [])
        }
    }, [settingsData?.viewKey])

    useEffect(() => {
        if (isFirstRender) return

        if (isIncludeRepeatFields && settingsData?.formKey) {
            loadRepeatableColumns(settingsData?.formKey, environment.apiToken)
            setAlertInfo((prev) => ({
                ...prev,
                open: true,
                type: 'success',
                text: 'Successfully include repeated fields',
            }))
        }
    }, [isIncludeRepeatFields])

    const handleColumnsCheck = (value) => () => {
        if (!Array.isArray(settingsData?.columnsChecked)) return
        const currentIndex = settingsData?.columnsChecked?.indexOf(value)
        const newChecked = [...settingsData?.columnsChecked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        handleSettingsChange('columnsChecked', newChecked)
    }

    const handleOtherOptionsCheck = (value) => () => {
        if (!Array.isArray(settingsData?.otherOptionsChecked)) return
        const currentIndex = settingsData?.otherOptionsChecked?.indexOf(value)
        const newChecked = [...settingsData?.otherOptionsChecked]

        if (currentIndex === -1) {
            if (value === 'showRepeatables' && !settingsData?.formKey) {
                setAlertInfo((prev) => ({
                    ...prev,
                    open: true,
                    type: 'error',
                    text: 'Please select a form first',
                }))
            } else {
                newChecked.push(value)
            }
        } else {
            newChecked.splice(currentIndex, 1)
        }

        handleSettingsChange('otherOptionsChecked', newChecked)
    }

    const handleSettingsChange = (name, value) => {
        setSettingsData((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSetPrimaryKey = (id) => {
        if (primaryKeyList.includes(id)) {
            setPrimaryKeyList((prev) => prev.filter((item) => item !== id))
        } else {
            setPrimaryKeyList((prev) => [...prev, id])
        }
    }

    const handleFieldsChange = (id, value) => {
        if (value === '') {
            // Remove the field from linkedFields
            setSettingsData((prev) => {
                const newLinkedFields = { ...prev.linkedFields }
                delete newLinkedFields[id]
                return {
                    ...prev,
                    linkedFields: newLinkedFields,
                }
            })
        } else {
            setSettingsData((prev) => {
                return {
                    ...prev,
                    linkedFields: {
                        ...prev.linkedFields,
                        [id]: value,
                    },
                }
            })
        }
    }

    const handleCancel = () => {
        onClose()
        setSettingsData((prev) => ({ ...prev, ...settings }))
    }

    const handleSubmit = () => {
        if (
            !settingsData?.viewKey ||
            !settingsData?.style ||
            settingsData?.columnsChecked?.length === 0
        ) {
            setAlertInfo((prev) => ({
                ...prev,
                open: true,
                type: 'error',
                text: t('common:dis.pleaseFillInformation'),
            }))
            return
        }

        // if (checkKeyIsExist('addNewButton', settingsData?.otherOptionsChecked) === true) {
        // 	if (isEmpty(settingsData?.connectedDoFormsKey)) {
        // 		setAlertInfo((prev) => ({
        // 			...prev,
        // 			open: true,
        // 			type: 'error',
        // 			text: t('common:dis.pleaseFillInformation'),
        // 		}))
        // 		return
        // 	}
        // }

        const currentDashboardKeyList = cloneDeep(dashboardKeyList)
        const tileName = tile.i
        const tileKey = tile.key
        const otherDashboardKeys = currentDashboardKeyList.filter(
            (item) => item.tileKey !== tileKey && item.tileName !== tileName
        )
        const primaryKeysToSave = [...otherDashboardKeys]
        if (!isEmpty(primaryKeyList)) {
            const newPrimaryKeyList = [
                ...primaryKeyList.map((item) => {
                    const primaryInfo = {
                        id: generateRandomID(),
                        key: item,
                        tileName,
                        tileKey,
                    }
                    if (item === 'Master_DateTime') {
                        primaryInfo['selectableTypes'] = ['DATE', 'DATETIME']
                    }
                    return primaryInfo
                }),
            ]
            const uniqNewPrimaryKeyList = uniqBy(
                newPrimaryKeyList,
                (item) => `${item.key}-${tile.tileName}-${tile.tileKey}`
            )

            // mark delete primary key
            const deletedPrimaryKeyList = currentDashboardKeyList
                .filter((item) => tileName === item.tileName && tileKey === item.tileKey)
                .map((oldItem) => {
                    const hasInNewList = uniqNewPrimaryKeyList.find(
                        (newItem) =>
                            oldItem.key === newItem.key &&
                            oldItem.tileName === newItem.tileName &&
                            oldItem.tileKey === newItem.tileKey
                    )

                    if (hasInNewList) return null

                    return {
                        ...oldItem,
                        deleted: true,
                    }
                })
                .filter((item) => !!item)

            const allTileKeys = [...uniqNewPrimaryKeyList, ...deletedPrimaryKeyList]

            const keepOldPrimaryKeyList = allTileKeys
                .map((item) => {
                    const deletedKey = deletedPrimaryKeyList.find(
                        (deletedItem) =>
                            deletedItem.key === item.key &&
                            deletedItem.tileName === item.tileName &&
                            deletedItem.tileKey === item.tileKey
                    )
                    if (Boolean(deletedKey)) {
                        return null
                    }

                    const oldDashboardKeyItem = currentDashboardKeyList.find(
                        (oldItem) =>
                            oldItem.key === item.key &&
                            oldItem.tileName === item.tileName &&
                            oldItem.tileKey === item.tileKey
                    )
                    if (Boolean(oldDashboardKeyItem)) {
                        return { ...oldDashboardKeyItem, old: true }
                    }
                    return item
                })
                .filter((item) => !!item)

            primaryKeysToSave.push(...keepOldPrimaryKeyList)
        }

        const orderedSaveList = orderBy(
            primaryKeysToSave,
            (item) => `${item.tileName}-${item.tileKey}-${item.id}`
        )

        onSubmit?.({
            projectKey: selectedView?.projectKey,
            formKey: selectedView?.formKey,
            viewKey: settingsData?.viewKey,
            style: settingsData?.style,
            columnsChecked: settingsData?.columnsChecked,
            otherOptionsChecked: settingsData?.otherOptionsChecked,
            primaryKeyList: orderedSaveList,
            linkedFields: settingsData?.linkedFields,
            connectedDoFormsKey: settingsData?.connectedDoFormsKey,
            firstLabel: settingsData?.firstLabel,
            secondLabel: settingsData?.secondLabel,
        })
    }

    const handleSelectDoFormsChange = (e) => {
        const key = e.target.value
        handleSettingsChange('connectedDoFormsKey', key)
    }

    return (
        <>
            <ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />

            <SideDialog
                defaultTileWidth={defaultTileWidth}
                open={open}
                onClose={handleCancel}
                width={tile?.w ?? 6}
                tileElementWidth={tileElementWidth}
                onResizeWidth={onResizeTileWidth}
            >
                <Stack
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem !important',
                        },
                        '& .MuiCheckbox-root': {
                            p: 0,
                        },
                    }}
                >
                    <Box sx={{ px: 1 }}>
                        <TextField
                            fullWidth
                            id="label-basic"
                            variant="standard"
                            value={tile.i}
                            disabled={true}
                            size="small"
                        />
                    </Box>

                    {!isFormList &&
                        (!viewLoading ? (
                            <Stack direction="row" alignItems="center" sx={{ my: 1 }}>
                                <FormControl
                                    variant="standard"
                                    sx={{ m: 1, minWidth: 120, flex: 1 }}
                                    size="small"
                                >
                                    <InputLabel id="view-select-small-label">
                                        {t('common:input.view')}
                                    </InputLabel>
                                    <Select
                                        labelId="view-select-small-label"
                                        id="view-select-small"
                                        value={settingsData?.viewKey ?? ''}
                                        label={t('common:input.view')}
                                        onChange={(e) => {
                                            handleSettingsChange('viewKey', e.target.value)
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {views?.length > 0 &&
                                            views.map((viewItem) => (
                                                <MenuItem value={viewItem.key} key={viewItem.key}>
                                                    {viewItem.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                                <Tooltip
                                    title={`${t('tooltip.refresh')}`}
                                    arrow
                                    placement="bottom-start"
                                    disableInteractive
                                    sx={{ flexShrink: 0 }}
                                >
                                    <span>
                                        <IconButton
                                            aria-label="refresh"
                                            size="small"
                                            sx={{ p: 2 }}
                                            onClick={reloadViews}
                                        >
                                            <RefreshOutlined fontSize="inherit" />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                {isShowViewFilter && tileEditSettingsComponent && (
                                    <Tooltip
                                        title={`View filter`}
                                        arrow
                                        placement="bottom-start"
                                        disableInteractive
                                        sx={{ flexShrink: 0 }}
                                    >
                                        {tileEditSettingsComponent}
                                    </Tooltip>
                                )}
                            </Stack>
                        ) : (
                            <Skeleton variant="rectangular" height={45} sx={{ mt: 2 }} />
                        ))}

                    <Box>
                        <Typography variant="subtitle2" sx={{ pl: 1 }}>
                            {t('common:chart.modalEvent')}
                        </Typography>
                        <FormControl
                            variant="outlined"
                            sx={{ width: '100% !important', marginTop: '5px', marginBottom: '5px' }}
                            size="small"
                        >
                            <InputLabel id="datagrid-select-small-label">
                                {t('common:chart.selectDoformsTile')}
                            </InputLabel>
                            <Select
                                labelId="datagrid-select-small-label"
                                id="datagrid-select-small"
                                value={settingsData?.connectedDoFormsKey ?? ''}
                                label={t('common:chart.selectDoformsTile')}
                                onChange={handleSelectDoFormsChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {doformsTile.map((tile) => (
                                    <MenuItem value={tile?.key} key={tile?.key}>
                                        {tile?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {!isFormList &&
                        (!fieldLoading ? (
                            <CheckboxList
                                title={t('common:dis.selectFields')}
                                enableIcon
                                onIconClick={handleSetPrimaryKey}
                                checkedIconList={primaryKeyList}
                                data={fields}
                                onItemClick={handleColumnsCheck}
                                checkedList={settingsData?.columnsChecked}
                                getLabel={(option) =>
                                    isShowDataNames ? option.name : option.title
                                }
                                getValue={(option) => option.name}
                            />
                        ) : (
                            <Skeleton variant="rectangular" height={180} sx={{ mb: 1 }} />
                        ))}

                    <DashboardList
                        title={t('common:dis.dashboardKeys')}
                        list={dashboardKeyList}
                        fields={fields}
                        onFieldsChange={handleFieldsChange}
                        linkedFields={settingsData?.linkedFields}
                        getLabelOption={(item) => item.name}
                        viewType={selectedView?.type}
                    />
                    
                    <DatagridTileLabel
                        title={t('common:dis.tileLabel')}
                        firstLabel={settingsData?.firstLabel}
                        secondLabel={settingsData?.secondLabel}
                        fields={fields}
                        handleSettingsChange={handleSettingsChange}
                    />

                    <CheckboxList
                        title={t('common:dis.otherOptions')}
                        data={tmpOpt}
                        onItemClick={handleOtherOptionsCheck}
                        checkedList={settingsData?.otherOptionsChecked}
                        getLabel={(option) => option.label}
                        getValue={(option) => option.value}
                        checkboxColor="warning"
                    />

                    <Stack direction="row" justifyContent="flex-end" mt={2}>
                        <Stack spacing={2} direction="row">
                            <Button
                                size="small"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isSubmitting || viewLoading}
                                startIcon={
                                    isSubmitting || viewLoading ? (
                                        <CircularProgress color="inherit" size="1em" />
                                    ) : null
                                }
                            >
                                {t('common:misc.ok')}
                            </Button>
                            <Button size="small" variant="text" onClick={handleCancel}>
                                {t('common:misc.cancel')}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </SideDialog>
        </>
    )
}

function CheckboxList({
    enableIcon = false,
    onIconClick,
    checkedIconList,
    title,
    data,
    onItemClick,
    checkedList,
    getLabel,
    getValue,
    checkboxColor = 'primary',
}) {
    const [t] = useTranslation('common')

    return (
        <>
            <Typography variant="subtitle2" sx={{ pl: 1 }}>
                {title}
            </Typography>
            <List
                sx={{
                    width: '100%',
                    minHeight: 180,
                    maxHeight: 180,
                    overflowY: 'scroll',
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    mb: 2,
                }}
                dense
            >
                {data?.length === 0 && (
                    <Typography px={2}>{t('common:dis.emptyFields')}</Typography>
                )}
                {data?.length > 0 &&
                    data.map((option) => {
                        if (option && option?.hide) {
                            return null
                        }
                        const labelId = `checkbox-list-label-${option.value}`
                        const optionValue = getValue(option)

                        return (
                            <ListItem
                                key={optionValue}
                                disablePadding
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        py: '2px',
                                    },
                                }}
                            >
                                <ListItemButton
                                    role={undefined}
                                    onClick={onItemClick(optionValue)}
                                    dense
                                    sx={{
                                        '& .MuiListItemIcon-root': {
                                            minWidth: '15px !important',
                                        },
                                    }}
                                >
                                    {enableIcon && (
                                        <Tooltip title={t('common:dis.makeKey')} placement="right">
                                            <IconButton
                                                edge="start"
                                                aria-label="comments"
                                                size="small"
                                                sx={{ mr: 1 }}
                                                onClick={(e) => {
                                                    e.stopPropagation()

                                                    onIconClick?.(optionValue)
                                                }}
                                            >
                                                <VpnKeyIcon
                                                    fontSize="small"
                                                    color={
                                                        checkedIconList?.indexOf(optionValue) !== -1
                                                            ? 'primary'
                                                            : 'inherit'
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <ListItemText id={labelId} primary={getLabel(option)} />
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="end"
                                            checked={checkedList?.indexOf(optionValue) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            color={checkboxColor}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                            size="small"
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
            </List>
        </>
    )
}

export default DatagridSettingsDialog
